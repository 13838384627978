import { ContentModules } from "@/utils/enums/ContentModules"

function defaultProps() {
  const { tenantId } = useHeaderStore()
  const { data: jobShop } = useNuxtData("jobShopData")
  const { pageType } = determinePageTrackingData()

  return {
    tenant_id: tenantId,
    jobshop_id: jobShop.value?.jobShopId,
    jobshop_name: `${jobShop.value?.jobShopTitle}`,
    page_path: window?.location.pathname,
    page_type: pageType,
    language: jobShop.value?.locale,
  }
}

/**
 * Counts elements of array arr by property name prop
 *
 * @param arr
 * @param prop
 */
function countBy(arr, prop) {
  return arr.reduce((prev, curr) => ((prev[curr[prop]] = ++prev[curr[prop]] || 1), prev), {})
}

function isCCM19Active(): boolean {
  return typeof window !== "undefined" && typeof CCM !== "undefined"
}

/**
 * Counts how often each content module is present.
 * Uses the ContentModules enum as a basis for mapping the names.
 *
 * @param trackingData
 */
function countedModules(trackingData: TrackingData) {
  // return null if no tracking data available (e.g. search page)
  if (!trackingData?.contentModules)
    return null

  const counted = countBy(trackingData.contentModules.value, "component")
  const result = {}

  for (const [key, value] of Object.entries(ContentModules)) {
    const lowerKey = key.toLowerCase()
    result[`module_${lowerKey}`] = counted[value] || 0
  }

  return result
}

/**
 * Determines and returns page tracking data based on the current route and job shop data.
 *
 * @returns {object} An object containing page tracking information.
 * @returns {string} pageType - The type of the page (e.g., 'homepage', 'search', or 'custom').
 * @returns {string} pageEventName - The event name for tracking the page (e.g., 'homepage_opened').
 * @returns {string} pagePath - The relative path of the current page.
 * @returns {string} pageTitle - The title of the page from the document.
 * @returns {string} trackedPagePath - The fully constructed tracking path including job shop vanity and page path.
 */
function determinePageTrackingData() {
  const { data: jobShop } = useNuxtData("jobShopData")
  const route = useRoute()

  let pageType: string
  let pageEventName: string
  let pagePath: string
  const pageTitle = document.title

  switch (route.name) {
    case "index":
      pageType = "homepage"
      pageEventName = "homepage_opened"
      pagePath = "/landingpage"
      break
    case "search":
      pageType = "search"
      pageEventName = "searchpage_opened"
      pagePath = "/search"
      break
    default:
      pageType = "custom"
      pageEventName = "custompage_opened"
      pagePath = `/custom-pages/${route.params.slug}`
  }

  const trackedPagePath = `virtual/${jobShop.value?.jobShopCompanyVanity}${pagePath}`

  return {
    pageType,
    pageEventName,
    pagePath,
    pageTitle,
    trackedPagePath,
  }
}

/**
 * Track events with Matomo or GTM.
 *
 * It can be called in each track function and additionally will track event with Matomo or GTM,
 * depending on isCookielessTrackingEnabled in the externalScriptSettings
 * @param eventCategory
 * @param eventAction
 * @param eventLabel
 * @param eventValue
 */

function trackCombinedMatomoGTM(eventCategory: string, eventAction: string, eventLabel: string, eventValue?: any) {
  const { data: jobShop } = useNuxtData("jobShopData")
  const _paq = window._paq || []

  if (jobShop?.value?.externalScriptSettings?.isCookielessTrackingEnabled) {
    _paq.push(["trackEvent", eventCategory, eventAction, eventLabel, eventValue])
  }
  else {
    // Small delay to ensure GTM is enabled and events are always tracked after pageView
    setTimeout(() => {
      window.dataLayer?.push({
        event: "jobfinderEvent",
        eventCategory,
        eventAction,
        eventLabel,
        eventValue,
      })
    }, 150)
  }
}

/**
 * Track page was loaded.
 *
 * It can be called directly on every page mounted,
 * because Amplitude sents old events, even after initialization
 *
 * - previous_pageurl: referer
 * - modules_amount: overall total amount of modules
 * - module_x: amount of module type x on the page
 *
 * @param trackingData needs to contain page and contentModules
 */
export function trackPageViewAmpl(trackingData?: TrackingData) {
  const { $amplitude } = useNuxtApp()
  const { pageEventName } = determinePageTrackingData()

  $amplitude.track(pageEventName, {
    previous_pageurl: document.referrer,
    modules_amount: trackingData?.contentModules?.value?.length || 0,
    ...countedModules(trackingData),
    ...defaultProps(),
  })
}

/**
 * Track event to GTM dataLayer .
 * Should be called after CCM19 consent given, so events aren't lost.
 */
export function trackPageViewGTM() {
  const gtm = useGtm()
  const { data: jobShop } = useNuxtData("jobShopData")
  const { pageTitle, trackedPagePath } = determinePageTrackingData()

  window.dataLayer?.push({
    companyId: jobShop.value?.jobShopId,
    matomoSiteId: jobShop.value?.externalScriptSettings?.matomoSiteId,
  })

  if (gtm?.enabled()) {
    gtm.trackView(pageTitle, trackedPagePath, {
      path: trackedPagePath,
      title: pageTitle,
    })
  }

  window.dispatchEvent(new CustomEvent("pageViewTracked"))
}

/**
 * Track page view event with matomo.
 */
export function trackPageViewMatomo() {
  const { pageTitle, trackedPagePath } = determinePageTrackingData()

  const _paq = window._paq || []

  _paq.push(["setCustomUrl", `${document.location.origin}/${trackedPagePath}`])
  _paq.push(["setDocumentTitle", pageTitle])
  _paq.push(["trackPageView"])

  window.dispatchEvent(new CustomEvent("pageViewTracked"))
}

/**
 * Track scroll depth
 *
 * percentage of page scroll
 */
export function trackScrollDepth() {
  const { $amplitude } = useNuxtApp()

  const totalHeight = document.documentElement.scrollHeight - window.innerHeight

  const currentScrollDepth = window.scrollY

  const percentage = ((currentScrollDepth / totalHeight) * 100).toFixed(2)

  trackCombinedMatomoGTM("scroll", "depth", "percent", percentage)

  $amplitude.track("scroll_depth", {
    percentage,
    ...defaultProps(),
  })
}

/**
 * Track clicks in top navigation on an item of different type
 *
 * - type: logo, link, language, CTA
 *
 *  name:
 * - Logo: value is "logo";
 * - Link: value is title of button;
 * - Language: value is name of language.
 * - CTA: title of button
 *
 * url: if it has one
 */
export function trackNavigationClicked(navItem: NavItem) {
  const { $amplitude } = useNuxtApp()

  // link || cta || logo
  let name = navItem.el?.label || navItem.el?.text || navItem.type
  if (navItem.type === "language")
    name = navItem.el?.details?.locale

  trackCombinedMatomoGTM("navigation", "click", navItem.el?.url)

  $amplitude.track("navigation_clicked", {
    type: navItem.type, // logo, link, language, cta
    name,
    link: navItem.el?.url,
    ...defaultProps(),
  })
}

/**
 * When a module is rendered
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 */
export function trackModuleExists(contentModules: ContentModule[], module: ContentModule) {
  const { $amplitude } = useNuxtApp()
  const { data: jobShop } = useNuxtData("jobShopData")

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  /**
   * trackCombinedMatomoGTM() is nested inside eventListener so it waits for the pageView event to be fired first.
   * This way Page URL is set correctly.
   * The condition handles the initial load of the page, the cookies list is empty if until the user close the banner
   * it helps to identify whether the banner is shown or not
   */
  if (jobShop?.value?.externalScriptSettings?.isCookielessTrackingEnabled) {
    window.addEventListener("pageViewTracked", () => {
      setTimeout(() => {
        trackCombinedMatomoGTM("module", "request", `module_name.${module.id}`, module.key)
      }, 150)
    })
  }
  else {
    const interval = setInterval(() => {
      if (isCCM19Active()) {
        if (!CCM.acceptedCookies.length) {
          window.addEventListener("ccm19CookieAccepted", () => {
            trackCombinedMatomoGTM("module", "request", `module_name.${module.id}`, module.key)
          })
        }
        else {
          window.addEventListener("pageViewTracked", () => {
            trackCombinedMatomoGTM("module", "request", `module_name.${module.id}`, module.key)
          })
        }
        clearInterval(interval)
      }
    }, 100)
  }

  $amplitude.track("module_requested", {
    module_name: module.key,
    template_name: module?.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id: module.id,
    with_CTA:
            module.config?.general?.ctaType?.value === "Button" // Cover
            || module.config?.general?.showCtaButton // Cards, Text
            || module.config?.general?.showCta // Chapter
            || module.key === "video_module" // Video
              ? 1
              : 0,
    position_on_page: pos,
    ...defaultProps(),
  })
}

/**
 * When a module is viewed (if called IntersectWrapper)
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 */
export function trackModuleViewed(contentModules: ContentModule[], module: ContentModule, child?: ContentModule) {
  const { $amplitude } = useNuxtApp()

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  trackCombinedMatomoGTM("module", "view", `module_name.${module.id}`, module.key)

  let module_name = module.key
  let module_id = module.id

  // track view of slides in slider instead
  if (module.key === "module-slider" && child?.key) {
    module_name = child.key;
    module_id = child.id
  }

  $amplitude.track("module_viewed", {
    module_name,
    template_name: module.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id,
    with_CTA:
            module.config?.general?.ctaType?.value === "Button" // Cover
            || module.config?.general?.showCtaButton // Cards, Text
            || module.config?.general?.showCta // Chapter
            || module.config?.general?.showButton // Slider
            || module.key === "video_module" // Video
              ? 1
              : 0,
    position_on_page: pos,
    ...defaultProps(),
  })
}

/**
 * When an interactive element inside a module is clicked
 *
 * - module_name - key of the module
 * - template_name - key of the template
 * - module_target - analytics setting
 * - module_id - backend given id
 * - with_CTA - 0/1 if no or yes
 * - position_on_page - zero based index
 * - cta_position: in case of categories/ cards which individual one was it, else dont send
 * - cta_name
 * - cta_link
 */
export function trackModuleClick(contentModules: ContentModule[], module: any, child?: any) {
  const { $amplitude } = useNuxtApp()

  let cta = module.config?.ctaButton
  let ctaPos
  if (module.modules.length) {
    // cards
    ctaPos = module.modules.findIndex(obj => obj.id === child.id)
    cta = child.config.ctaButton
  }

  if (module.config?.general.categories) {
    // categories
    ctaPos = module.config.general.categories.findIndex(obj => obj.id === child.id)
  }

  // standalone video
  if (module.key === "module-video") {
    cta = {
      text: "video_play_button",
      link: {
        url: module.config.general.video.source.value,
        type: "video",
      },
    }
  }

  // video in a gallery
  if (module.key === "module-gallery" && child.key === "module-video") {
    cta = {
      text: "video_play_button",
      link: {
        url: child.config.general.video.source.value,
        type: "video",
      },
    }
  }

  const pos = contentModules.findIndex((obj) => {
    return obj.id === module.id
  })

  // Set link url to scrollTarget id if link is internal
  if (cta && cta.link?.type.value === "internal")
    cta.link.url = `#${cta.link.scrollTarget.value}`

  trackCombinedMatomoGTM("module", "click", `module_name.${module.id}.${cta?.link.url}`, pos)

  $amplitude.track("module_clicked", {
    module_name: module.key,
    template_name: module.config?.general?.template.component,
    module_target: module.config?.general?.analytics?.value,
    module_id: module.id,
    position_on_page: pos,
    cta_position: ctaPos !== -1 ? ctaPos : undefined,
    cta_name: cta?.text || child?.title, // all others || categories
    cta_link: cta?.link.url || child?.url,
    ...defaultProps(),
  })
}

// Search & Filters tracking events

/**
 * When a filter is loaded
 *
 * @param filterName
 */
export function trackFilterLoaded(filterName: string) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("filter_loaded", {
    filter_name: filterName,
    ...defaultProps(),
  })
}

/**
 * When a filter is opened by clicking on it
 *
 * @param filterName
 */
export function trackFilterOpened(filterName: string) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("filter_opened", {
    filter_name: filterName,
    ...defaultProps(),
  })
}

/**
 * When a filter value is selected
 *
 * @param filterName
 * @param filterValue
 */
export function trackFilterSelected(filterName: string, filterValue: string) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("filter_selected", {
    filter_name: filterName,
    filter_value: filterValue,
    ...defaultProps(),
  })

  trackCombinedMatomoGTM("filter", "select", `${filterName}.${filterValue}`)
}

/**
 * When the button to remove all filters is clicked
 */
export function trackClickRemoveAllFilter() {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("filter_clicked_remove_all", {
    ...defaultProps(),
  })
}

/**
 * When the search results are loaded
 *
 * @param numberOfResults
 */
export function trackLoadSearchResults(numberOfResults: number) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("search_results_loaded", {
    number_of_results: numberOfResults,
    ...defaultProps(),
  })
}

/**
 * When a search result is viewed
 *
 * @param jobId
 * @param jobTitle
 * @param resultPosition
 */
export function trackViewSearchResult(jobId: string, jobTitle: string, resultPosition: number) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("search_result_viewed", {
    job_id: jobId,
    job_title: jobTitle,
    result_position: resultPosition,
    ...defaultProps(),
  })

  trackCombinedMatomoGTM("search_result", "view.id", jobId)
  trackCombinedMatomoGTM("search_result", "view.position", `${resultPosition}`)
}

/**
 * When a search result is clicked
 *
 * @param jobId
 * @param jobTitle
 * @param resultPosition
 */
export function trackClickSearchResult(jobId: string, jobTitle: string, resultPosition: number) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("search_result_clicked", {
    job_id: jobId,
    job_title: jobTitle,
    result_position: resultPosition,
    ...defaultProps(),
  })

  trackCombinedMatomoGTM("search_result", "click.id", jobId)
  trackCombinedMatomoGTM("search_result", "click.position", `${resultPosition}`)
}

/**
 * When the user enters a search term
 *
 * @param searchRequest
 */
export function trackSearchApplied(searchRequest: string) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("search_applied", {
    search_request: searchRequest,
    ...defaultProps(),
  })

  trackCombinedMatomoGTM("search", "apply", searchRequest)
}

/**
 * When the user changes the page of the search results
 *
 * @param changeType
 */
export function trackSearchResultsPageChanged(changeType: string) {
  const { $amplitude } = useNuxtApp()

  $amplitude.track("search_results_page_changed", {
    change_type: changeType,
    ...defaultProps(),
  })

  trackCombinedMatomoGTM("search_results", "page_changed", changeType)
}
